.login-main-form {
    width: 460px !important;
    margin: 0 auto  !important;
    display: table;
    position: relative;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 30px;
}
.log div button.btn {
    width: 100%;
    border: 0;
    font-weight: 600;
    font-size: 15px;
    padding: 10px 0;
    border-radius: 50px;
    transition: all .4s ease;
}

.log-bot ul li {
    float: left;
    padding: 0 8px;
}
.log-bot ul li span {
    color: #333;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
}
.log-bot ul {
    margin: 0 auto;
    display: table;
}

.login.login-new {
    padding: 20px 20px 15px;
}
.log-bot {
    padding: 20px;
}




.user-tcon {
    float: left;
    width: 100%;
    position: relative;
}
.user-tc-img {
    width: 100%;
    margin-bottom: 35px;
}
.user-tc-img img {
    width: 15%;
    margin: 0 auto;
    display: table;
}
.user-tc-diff ul li {
    float: left;
    width: 50%;
}
.pri-box {
    background: #fff;
    border-radius: 11px;
    transition: all .4s;
    box-shadow: 0 5px 25px -7px #00000038;
    border: 0 solid #f0f4f7;
    /* margin: 0 2%; */
    overflow: hidden;
}
.user-tc-diff ul li .pri-box .c6 {
    text-align: center;
    padding: 25px 5px 20px;
}
.pri-box .c4 {
    padding: 7px 20px 20px;
    width: 100%;
}
.user-tc-diff ul li .pri-box .c6 img {
    width: 100px;
}
.pri-box .c4 ol li {
    width: 100%;
    text-align: left;
    padding-bottom: 12px;
    font-size: 15px;
    color: #334e59;
    font-weight: 500;
    padding-left: 35px;
    box-sizing: border-box;
    border: 0;
    background: none;
    position: relative;
}

