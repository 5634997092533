.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-hero {
  border-radius: 2px;
  box-shadow: 0px 0px 12px 2px rgb(0, 0, 0, 0.07); /* Added box-shadow */
  padding: 25px 23px;
  margin: 20px 20px ;
  width: 300px;
  text-align: center;
}
.card-hero:hover {
  transition: all 0.2s ease;
  transform: translateY(-5px);
  z-index: 9;
  box-shadow: 0px 4px 30px 6px rgb(0, 0, 0, 0.07);
}

.card-image {
  width: 60px;
  height: auto;
  border-radius: 8px 8px 0 0;
}

.card-content {
  margin-top: 15px;
}

.card-heading {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-paragraph {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
}

.card-button {
  padding: 4px 7px;
  border-radius: 30px;
  font-size: 13px;
  border: 1px dashed #fff;
  box-shadow: 0 0 0 3px #151f31;
  background-color: #3f4550;
  color: #fff;
}

.card-button:hover {
  background-color: #32363e;
}

.second-card {
  box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.15); /* Added box-shadow */
  margin: 20px;
  width: 300px;
  text-align: center;
}

.second-card:hover {
    box-shadow: 0px 11px 9px -10px rgba(0, 0, 0, 0.52);
    transition: all 0.5s ease;
    transform: translateY(-5px);
}

.second-card-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
  object-fit: cover;
}

.second-card-content {
  margin-top: 15px;
}

.second-card-heading {
  font-size: 20px;
  margin-bottom: 15px;
  margin-left: 10px;
  text-align: left;
}

.second-card-paragraph {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
}
.ratstar {
  color: #ff9800; /* or any color you prefer */
}

.view-btn {
  color: #ffffff;
  padding: 10px 40px;
  border-radius: 2px;
  font-size: 14px;
  display: table;
  margin: 0 auto;
  border: 1px solid #3675b6;
  background: #075376 !important;
  margin-top: 14px;
  box-shadow: 0px 11px 9px -10px rgba(0, 0, 0, 0.52);
}
.view-btn a:hover{
  box-shadow: 0px 11px 9px -10px rgba(0, 0, 0, 0.72);
  transition: all 0.5s ease;
  transform: translateY(-5px);
}
button.view-btn {
  background: none;
}

.home-city ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0px;
}

.home-city li {
  width: 100%; /* On smaller screens, each item takes full width */
  margin-bottom: 15px;
}

.hcity > div:first-child {
  flex: 0 0 50%; /* First div (image) takes 50% width */
}

.hcity > div:last-child {
  flex: 0 0 50%; /* Last div (content) takes 50% width */
  padding-left: 20px; /* Add some spacing between image and content */
}

.hcity-hcity > div:last-child {
  flex: 0 0 50%;
  padding-left: 20px;
  margin-top: -12px;
}
.hcity img {
  width: 100%;
  height: 195px;
  display: block;
  border-radius: 5px;
  object-fit: cover;
}
.hcity-image img {
  width: 615px;
  border-radius: 5px;
  height: 400px;
}
label.rat-first {
  margin-right: 5px;
  font-size: 14px;
}
.list-rat-all-first {
  margin: 4px 0px;
  display: flex;
  align-items: center;
}
.list-rat-all-first b {
  background-color: #ff9800;
  padding: 2px 5px;
  border-radius: 2px;
  margin-right: 5px;
  font-size: 14px;
}
.hcity-text {
  position: relative;
  color: white;
  top: -90px;
  z-index: 9999;
}
.hcity-text {
  position: relative;
  color: white;
  top: -90px;
  z-index: 9;
}
.hcity-text h4 {
  margin: 0;
}
.hcity {
  height: 205px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.hcity-hcity {
  width: 615px;
  height: 400px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.hcity:hover{
  transition: all 0.5s ease;
  transform: translateY(-5px);
}
.hcity-hcity:hover {
  transition: all 0.5s ease;
  transform: translateY(-5px);
  box-shadow: 0px 16px 11px -7px rgba(0, 0, 0, 0.68);
}

.hcity-hcity:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #41494fdb, #b7a23a00 100%);
  z-index: 2;
  left: 0;
  top: 0px;
  border-radius: 5px;
  transition: all 0.5s ease;
}

.hcity:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #41494fdb, #b7a23a00 100%);
  z-index: 2;
  left: 0;
  top: -10px;
  border-radius: 5px;
  transition: all 0.5s ease;
}
/* ServiceCard.css */

.section-service {
  background-color: #f6f6f7;
  position: relative;
}
.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-card {
  width: calc(51% - 20px); /* Adjust width to fit two cards per row with margin */
  background-color: white;
  padding: 20px 15px;
  margin-bottom: 20px;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0px 8px 7px -10px rgba(0, 0, 0, 0.5);
}

.service-card:hover {
  box-shadow: 0px 11px 9px -10px rgba(0, 0, 0, 0.52);
  transition: all 0.5s ease;
  transform: translateY(-5px);
}

.service-card img {
  width: 100%;
  height: 120px;
  border-radius: 2px;
  object-fit: cover;
}

.service-card h2 {
  margin-top: 10px;
  font-size: 25px;
  color: #000000;
}

.service-card h3 {
  margin-top: 5px;
  color: #000;
  font-size: 18px;
}
.service-card {
  position: relative;
  overflow: hidden;
}

.service-rating {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
}
.service-card p {
  margin-top: 10px;
  color: #3b3a3a;
  font-size: 16px;
}

.service-card b {
  position: absolute;
  background: #4caf50;
  padding: 3px 7px;
  font-weight: 600;
  color: #fff;
  right: 17px;
  top: 15px;
  font-size: 12px;
  border-radius: 2px;
}

.city-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.city{
  position: relative;
  width: 400px; /* Adjust width to fit two cards per row with margin */
  background-color: white;
  padding: 20px 15px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 10px;
  border: 1px rgb(150, 150, 150, 0.29);
  box-shadow: 0px 7px 20px -5px rgb(150, 150, 150, 0.29);
  overflow: hidden;

}
.city-head {
  background-color: #0291d2;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
  margin-left: -17px;
  margin-right: -16px;
}

.city-head h4{
color: white;
padding: 12px 0px;
font-weight: 500;
font-size: 18px;

}

.city-head:after, .city-head:before {
  top: 45px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.city-head:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #0291d2;
  border-width: 13px;
  margin-left: -9px;
  z-index: 9;
}

.image {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;
}
.image:hover {
  box-shadow: 0px 7px 20px -5px rgb(150, 150, 150, 0.29);
}
.image img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

.image-details {
  float: left;
  padding: 0px 15px;
  width: 80%  ;
}

.image b {
  position: absolute;
  background: #4caf50;
  padding: 3px 7px;
  font-weight: 600;
  color: #fff;
  right: 5px;
  top: 20px;
  font-size: 10px;
  border-radius: 2px;
}


.image-details h3 {
  margin-top: 15px;
  margin-bottom: 0px;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.image-details p {
  font-size: 12px;
  color: #525252;
  padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  font-weight: 400;
}



.hom-cre-acc-left {
  font-family: Arial, sans-serif;
  margin-left: 60px;
  padding: 0;
}
.hom-cre-acc-left ul{
 margin: 0;
 padding: 0;
}

.hom-cre-acc-left h3 {
  font-size: 42px;
  font-weight: 400;
  color: #333;
}

.hom-cre-acc-left h3 span {
  font-weight: 600;
}

/* Style the paragraph */
.hom-cre-acc-left p {
  font-size: 1rem;
  color: #666;
}
.hom-cre-acc-left ul li {
  display: flex;
  margin: 20px 0px 0px;
}
/* Style the icons */
.hom-cre-acc-left img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

/* Style the content inside the list items */
.hom-cre-acc-left div {
  flex-grow: 1;
}

/* Style the title inside the list items */
.hom-cre-acc-left h5 {
  font-size: 1.2rem;
  color: #333;
}
.pad-tb {
  padding-top: 100px;
  padding-bottom: 40px;
}
/* Style the paragraph inside the list items */
.hom-cre-acc-left p {
  font-size: 15px;
  color: #666;
}
.common-heading {
  padding: 0;
  text-align: center;
  position: relative;
  z-index: 10;
}
.text-l {
  text-align: left !important;
}
.common-heading>span {
  color: #e60072;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 15px;
  display: block;
}
.common-heading h2 {
  margin-bottom: 60px;
}

@media only screen and (max-width:767px) {
  .service-container {
    display: block !important;
}
.service-card {
  width: auto !important;
}
.card-heading {
  font-size: 10px;
  margin-bottom: 10px;
}
.service-card p {
  margin: 0px;
  font-size: 13px !important;
}  
.service-card h2 {
  font-size: 20px !important;
}
.card-paragraph {
  font-size: 16px;
  display: none;
  color: #666;
  margin-bottom: 15px;
}
.card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    justify-content: center;
}
.card-button {
  padding: 2px 6px;
  border-radius: 30px;
  font-size: 10px;
}
.card-image {
  width: 29px;
  margin: 0 auto;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0 0!important;
}
.card-hero {
  padding: 15px 8px;
  margin: 5px 5px;
  width: 86px;
  height: 92px;
}
.card-button {
  display: none;
}

.card {
  max-width: calc(48% - -3px);
  border: 1px solid #ccc;
  border-radius: 10px !important;
  width: 201px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 252px !important;
  margin: 5px 2px;
}
.col-lg-2.col-sm-4.col-xs-6{
  width: 50%;
}
.testimonials .item {
  padding:0px !important;
  margin-bottom: 0px !important;
}
.testimonials {
  padding: 1px 0 60px !important;
}
/* Testimonial.css */

.item {
  opacity: 1; /* Set default opacity for all cards */
}
.category-section {
  padding: 0px 0 0px !important;
  background-color: #fff;
}
.slide-img img {
  width: 99.3% !important;
  height: 180px !important;
}
.prev-slider, .next-slider {
  top: 50%;
  transform: translateY(-50%);
  padding: 10px !important;
}
.next-slider:before {
  left: 2px !important;
  top: 10px !important;
  width: 20px !important;
  height: 20px !important;
}
.prev-slider:before {
  left: 2px !important;
  top: 10px !important;
  width: 20px !important;
  height: 20px !important;
}
}

.testimonials {
  padding: 40px 0 70px ;
}
.testimonials .item p{
  margin: 5px 0 0;
}
.testimonials .container {
  max-width: 1200px;
  margin: 0 auto;
}
.stars{
  font-size: 40px;
  margin: 0 0 5px;
  color: orange;
}

.testimonials .item {
  text-align: center;
  padding:0 50px;
  margin-bottom: 20px;
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
}

.testimonials .item img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 17px;
  object-fit: cover;
}
.testimonials .testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  background: #3190E7;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05);
}

.testimonials .slick-dots {
  display: flex !important;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.testimonials .slick-dots li {
  margin: 0 5px;
}

.testimonials .slick-dots li button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
button.slick-arrow.slick-prev {
  display: none !important;
}
button.slick-arrow.slick-next {
  display: none !important;
}
.testimonials .slick-dots li button:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #3190E7;
  border-radius: 50%;
  display: block;
}

.testimonials .slick-dots li.slick-active button:before {
  background-color: #3190E7;
}

/* Testimonial.css */

.item {
  opacity: 0.7; /* Set default opacity for all cards */
}

.middle-card {
  opacity: 1 !important; /* Override opacity for the middle card */
}
/* Testimonial.css */
/* Testimonial.css */
.middle-card {
  opacity: 1 !important;
  transition: opacity 0.3s ease-in-out;
}

.item {
  opacity: 0.5; /* Adjust opacity as needed */
  transition: opacity 0.3s ease-in-out;
}



.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ECECEC;
  box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
}

@media only screen and (max-width: 999px) and (min-width: 767px) {
  
  .list-rat-all-first{
    display: none;
  }

}

.slider-container-main {
  width: 100%;
  margin: 10px auto;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
}

.slider-image {
  width: 200%;
  display: flex;
  transition: transform 0.5s ease;
}


.slide-img img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.prev-slider, .next-slider {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
  background-color: #333;
  color: #fff;
  border: none;
  z-index: 1;
  opacity: 0.5;
}

.prev-slider:hover ,.next-slider:hover {
 opacity: 1;
}

.prev-slider {
  left: 10px;
  background-color: white;
  border-radius: 50%;
}
.prev-slider:before {
  content: "";
  font-size: 42px;
  line-height: 0;
  text-align: center;
  left: 4px;
  color: #333;
  background: url('../Assets/icon/next.webp') no-repeat;
  width: 24px;
  height: 24px;
  background-size: cover;
    transform: rotate(180deg);
    margin: 0px 0px 0px 11px;
}


.next-slider {
  right: 10px;
  background-color: white;
  border-radius: 50%;
}
.next-slider:before {
  content: "";
  font-size: 42px;
  line-height: 0;
  text-align: center;
  left: 4px;
  color: #333;
  background: url('../Assets/icon/next.webp') no-repeat;
  width: 24px;
  height: 24px;
  background-size: cover;
  margin: 0px 0px 0px 17px;
}
